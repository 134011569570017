<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-promotions' }">{{
            $t("MENU.ITEM.PROMOTIONS.PROMOTIONS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingPromotion"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <PromotionForm ref="Form" :form="form"></PromotionForm>
          <v-overlay :value="isLoadingPromotion">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingPromotion"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_PROMOTION } from "@/modules/promotion/store/promotion.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";

import PromotionForm from "../components/PromotionForm.vue";

export default {
  components: {
    KTCard,
    SaveButtonDropdown,
    PromotionForm
  },
  data() {
    return {
      errors: {},
      promotion: {},
      form: {
        name: null,
        shopId: null,
        url: null,
        promoCode: null,
        categories: [],
        description: null,
        from: null,
        to: null,
        image: null,
        active: true
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingPromotion"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Промоции", route: { name: "list-promotions" } },
      { title: "Добавяне на промоция" }
    ]);
  },
  methods: {
    prepareFormData() {
      const { image, ...rest } = this.form;
      let formData = new FormData();
      formData.append("image", image);
      formData.append("payload", JSON.stringify(rest));
      return formData;
    },
    onSave(next) {
      this.$refs.Form.$v.form.$touch();
      if (this.$refs.Form.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля попълнете всички задължителни полета"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_PROMOTION, this.prepareFormData())
        .then(data => {
          this.errors = data.data.errors;
          let itemEdit = data.data;
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-promotion",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-promotion" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-promotions" });
          }
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    }
  }
};
</script>
